<template>
  <div class="three-picture">
    <optionscard :deliverParentNum="3"></optionscard>
    <!-- 宽度1200的div -->
    <div class="width1200a">
      <div
        class="pitures"
        v-for="item in picturesList"
        :key="item.category_id"
        @click="picture(item)"
      >
        <img class="zpflPic" :src="item.image" width="100%" height="100%"/>
        <div class="zpflDiv">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard
  },
  data () {
    return {
      // 存放数据
      picturesList: []
    }
  },
  created () {
    this.getPitures()
  },
  methods: {
    // 跳转
    picture (item) {
      const data = JSON.stringify(item)
      console.log('data', data)
      this.$router.push({
        path: '/third/cgzpfirst/cgzpsecond',
        query: { data }
      })
      console.log('item', item)
    },
    // 获取数据
    async getPitures () {
      const { res } = await this.$api.venueexhibits.getPictures({ pid: 3 })
      console.log('res', res)
      this.picturesList = res
      console.log('picturesList', this.picturesList)
    }
  }
}
</script>

<style lang="scss" scoped>
.pitures {
  width: 1196px;
  height: 250px;
  margin-top: 30px;
  cursor: pointer;
  position: relative;
}

.zpflPic{
  object-fit: cover;
}

.zpflDiv{
  position: absolute;
  top: 45%;
  left: 45%;
  color: #fff;
  line-height: 26px;
  font-size: 33px;
}

.width1200a {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
}
</style>
